import { TranslateService } from '@ngx-translate/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown'
import { ApiService } from '../../../services/api.service'
import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core'
import { ConstantsService } from 'src/app/services/constants.service'
import { trigger, state, style, animate, transition } from '@angular/animations'
import { HttpClient } from '@angular/common/http'

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: false, autoClose: true } }],
    animations: [
        trigger('collapseAnimation', [
            state(
                'collapsed',
                style({
                    height: '0',
                    overflow: 'hidden'
                })
            ),
            state(
                'expanded',
                style({
                    height: '*',
                    overflow: 'hidden'
                })
            ),
            transition('collapsed <=> expanded', animate('500ms ease-in-out'))
        ])
    ]
})
export class HeaderComponent implements OnInit {
    subOption = true
    lgSubOption = true
    europeanList: any = []
    ukList: any = []
    storageList: any = []
    isCollapsed = true
    isEuropeanRemovalsExpanded = false
    isManExpanded = false
    isHouseExpanded = false
    isStorageExpanded = false
    isPackingExpanded = false
    isEuroExpanded = false

    carSubscribe: any
    isAuthenticated = false
    isAdmin = false
    isCustomer = false
    isTradePartner = false
    languages = this.cs.LANGUAGES
    lang: any
    userType = ''
    hasCustomer: boolean = false
    hasTradePartner: boolean = false
    isDropdownOpen: boolean
    constructor(
        public api: ApiService,
        public cs: ConstantsService,
        public router: Router,
        private route: ActivatedRoute,
        public renderer2: Renderer2,
        public ts: TranslateService,
        private http: HttpClient
    ) {
        this.ts.currentLang = this.api.getCurrentLang()
        api.userLoggedInObs.subscribe((m) => {
            this.isAuthenticated = m
            if (this.isAuthenticated) {
                this.loginUpdates()
            }
        })

        this.lang = this.api.translate('website.header')
        this.lang.subscribe((d: any) => {
            this.lang = d
        })

        this.gettingEuropeanRmovalsList()
        this.gettingUkList()
        this.gettingStorageList()
    }
    loginUpdates(): void {
        this.isAdmin = this.api.isAdmin()
        this.isCustomer = this.api.isCustomer()
        this.isTradePartner = this.api.isTardePartner()
    }

    ngOnInit() {
        let user: any = localStorage.getItem('user') as string

        if (user) {
            let data = JSON.parse(user)
            if (data.userType == 'Customer') {
                this.hasCustomer = true
            }
            if (data.userType == 'TradePartner') {
                this.hasTradePartner = true
            }
        }
    }

    gettingEuropeanRmovalsList() {
        const url = `${this.api.baseUrl}/european-removal-service/list`
        this.http.get(url).subscribe((resp: any) => {
            this.europeanList = resp.data
        })
    }
    gettingUkList() {
        const url = `${this.api.baseUrl}/uk-service/list`
        this.http.get(url).subscribe((resp: any) => {
            this.ukList = resp.data
        })
    }
    gettingStorageList() {
        const url = `${this.api.baseUrl}/storage-service/list`
        this.http.get(url).subscribe((resp: any) => {
            this.storageList = resp.data
        })
    }

    selectService(serviceType: any) {
        this.api.setSelectedServiceId(serviceType)
        // this.router.navigate(['/elite-services'], { queryParams: { id: serviceId } })
    }

    logOut(): void {
        const check = this.api.logOut()
        if (check) {
            window.location.href = '/'
        }
    }

    openFile() {
        window.open('assets/images/terms-condition.pdf', '_blank')
    }

    setCollapsed() {
        this.isCollapsed = !this.isCollapsed
    }

    scrollToElement($element: any) {
        document.getElementById('nav-check')?.click()
        const element = document.querySelector($element)
        console.log('element', element)
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            })
        }
    }

    setSubOption() {
        this.subOption = !this.subOption
    }

    setLgSubOption() {
        this.lgSubOption = !this.lgSubOption
    }

    toggleEuropeanRemovals() {
        this.isEuropeanRemovalsExpanded = !this.isEuropeanRemovalsExpanded
    }
    toggleMan() {
        this.isManExpanded = !this.isManExpanded
    }
    toggleHouse() {
        this.isHouseExpanded = !this.isHouseExpanded
    }
    toggleStorage() {
        this.isStorageExpanded = !this.isStorageExpanded
    }
    togglePacking() {
        this.isPackingExpanded = !this.isPackingExpanded
    }
    toggleEuro() {
        this.isEuroExpanded = !this.isEuroExpanded
    }
    openDropdown() {
        this.isDropdownOpen = true
    }
    closeDropdown() {
        this.isDropdownOpen = false
    }

    encodeServiceType(type: string): string {
        return type.replace(/\s+/g, '');
    }
}
